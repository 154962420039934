<template>
  <layout :title="$route.name" :options="options">
    <v-flex xs12 class="pa-3">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left fonte">
                NOME
              </th>
              <th class="text-left fonte">
                DESCRIÇÃO
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in getTiposDeDespesas"
              :key="item._id"
              @click="abreModalViewTipoDeDespesa(item)"
            >
              <td class="font-weight-bold">{{ item.nome }}</td>
              <td class="">{{ item.descricao }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-flex>
    <ModalViewTipoDeDespesa />
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalViewTipoDeDespesa from "./components/modalViewTipoDeDespesa.vue";
export default {
  data() {
    return {
      options: [
        {
          nome: "Cadastrar Tipo de Despesa",
          action: () => this.abreModalViewTipoDeDespesa({})
        }
      ]
    };
  },
  components: {
    ModalViewTipoDeDespesa
  },
  computed: {
    ...mapGetters(["getTiposDeDespesas"])
  },
  methods: {
    ...mapActions(["listarTiposDeDespesas", "abreModalViewTipoDeDespesa"])
  },
  created() {
    this.listarTiposDeDespesas();
  }
};
</script>
